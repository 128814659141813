namespace Models
{
    export class AddTab
	{
        selector: string;
        code: string;
        display: string;
        content: string;
        position: number;
        activate: boolean;
        activateIfExists : boolean;
        allowClose: boolean;
        scrollToTab: boolean;
		fullScreenTab: boolean;
		instanceId: string;
        
        constructor(parameters: any) 
		{
            this.selector = parameters.Selector;
            this.code = parameters.Code;
            this.display = parameters.Display;
            this.content = parameters.Content;
            this.position = parameters.Position;
            this.activate = parameters.Activate;
		    this.activateIfExists = parameters.ActivateIfExists;
            this.allowClose = parameters.AllowClose;
            this.scrollToTab = parameters.ScrollToTab;
            this.fullScreenTab= parameters.FullScreenTab;
			this.instanceId = parameters.InstanceId;
        }
    }
}